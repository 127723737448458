function placeholderElement() {
    document.querySelectorAll('[data-placeholder-element]').forEach((el) => {
        const placeholderSelector = el.getAttribute('data-placeholder-element');
        const placeholderElement = document.querySelector(placeholderSelector);

        // Maak een ResizeObserver aan voor dit specifieke element
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                // Bereken de hoogte
                let placeholderElementHeight = entry.contentRect.height;

                // Bereken de breedte, maximaal de breedte van het venster
                let placeholderElementWidth = Math.min(
                    entry.contentRect.width,
                    window.innerWidth
                );

                // Pas de stijl van het placeholder-element aan
                el.style.height = `${placeholderElementHeight}px`;
                el.style.width = `${placeholderElementWidth}px`;
            }
        });

        // Start de observatie van het bronelement
        resizeObserver.observe(placeholderElement);
    });
}

document.addEventListener('DOMContentLoaded', placeholderElement);

// Aanroepen bij initiële paginalading
window.addEventListener('load', placeholderElement);

// Optioneel: ook aanroepen bij resize van het venster
window.addEventListener('resize', placeholderElement);

