window.g_dev = false;
window.g_windowHeight = window.innerHeight;
window.g_windowWidth = window.innerWidth;
window.g_scroll = window.scrollY;
window.scrollMargin = 0;
window.scrollTreshold = 0;

window.addEventListener('resize', function(event){
    window.g_windowHeight = window.innerHeight;
    window.g_windowWidth = window.innerWidth;
});

import './scripts/placeholder-element';
